import React from "react";
import "../../App.css";
import Services from "../Services";
import Footer from "../Footer";

export default function ServicesPage() {
  return (
    <>
      <div className="services">
        <h1 className="">SERVICES</h1>
        <h3>
          We specialize in innovation with cutting-edge Software Development,
          and tycoon Websites, Apps, Systems, and APIs.
        </h3>
      </div>

      <Services />
      <Footer />
    </>
  );
}
