import React from "react";
import "./Services.css";

const services = {
  "Software Development": {
    img: "/images/icons/new4.jpg",
    text: [
      "Website Development: Tailored Solutions for online empire🕸. let you scale up your online empire with tte stunning websites we make for you.",
      "system development: Desktop and web-based apps to simplify your work and amplify your earnings",
      "mobile app development: Get equipped with our innovative mobile app solutions.",
    ],
  },
  "Dynamic Digital Marketing & Branding Solutions": {
    img: "/images/dd1.jpg",
    text: [
      "Social media marketing",
      "Content marketing",
      "Search engine optimization (SEO)",
      "Pay-per-click (PPC) advertising",
    ],
  },

  "Electro Mechanical": {
    img: "/images/icons/icon5.png",
    text: [
      "Reach us to Enjoy our products and services",
      "General purpose machines",
      "Critical maintainances",
      "    Failure modification and solutions",
      "    Additive machine elements",
    ],
  },
};

const Services = () => {
  return (
    <div className="service-container">
      <h1>Services We Provide</h1>

      <div className="scard">
        {Object.entries(services).map(([title, { img, text }]) => (
          <div className="service-card" key={title}>
            <div className="image">
              <img src={img} alt={title} />
            </div>
            <h3>{title}</h3>
            <ul>
              {text.map((temp, index) => (
                <li key={index}>{temp}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
