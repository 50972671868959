import React from "react";
import "./FeedsBlock.css";
import FeedsBlockItem from "./FeedsBlockItem";

const FeedsBlock = () => {
  const text = [
    "One of our products Togta has been working in partnership with Bureau of farm and TDA to address the issue of locust in Tigrai since 2021. It has verified its effective solution by expelling swarm locust from large areas by covering one hectar(200m diameter 4D) area at once.",
    "This has also got great attention as it can be used after the locust outbreak extending its being solution for other  agricultural consumers like birds 🐦, moneys, squirrells etc",
    "Reach out to your region farms and irrigations or support your  region from the hazzard of locust outbreak.",
  ];
  return (
    <div className="feed-block">
      <h1>Updates </h1>

      <FeedsBlockItem
        newFeed="newFeed-reverse"
        title="Togta at service to society"
        text={text}
        img1="/images/l2.jpg"
        img2="/images/f2.jpg"
      />
      {/* <hr /> */}
      {/* <FeedsBlockItem
        newFeed="newFeed"
        title="Production and activation"
        text={text}
        img1="/images/9.jpeg"
        img2="/images/10.jpeg"
      /> */}
    </div>
  );
};

export default FeedsBlock;
