import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  const cardItems = [
    {
      src: "images/products/p3.jpg",
      text: "Digitalize your promotion. you can promote your skills, programs and services using this product.",
      label: "DBcard",
      path: "https://dbcard.tugza.com",
    },

    {
      src: "images/products/p2.jpg",
      text: "All rounded solution for office and clients management.",
      label: "Efoyta-office automation",
      path: "https://office.tugza.com",
    },
    {
      src: "images/products/p1.jpg",
      text: "Authenticate your football games, concerts, conferences and so much more with this product.",
      label: "E-handler",
      path: "https://event.tugza.com",
    },
    {
      src: "images/f1.jpg",
      text: "Agricultural product consuming animal's expeller technology anti > locust, birds, monkey and other consumers",
      label: "Togta",
      path: "/products",
    },
    {
      src: "images/products/p7.jpg",
      text: "A product that automate the xxx systems effectively",
      label: "xms",
      path: "/products",
    },
  ];

  const groupedCards = [
    cardItems.slice(0, 2),
    cardItems.slice(2, 4),
    // cardItems.slice(4, 7),
  ];

  return (
    <div className="cards">
      <h1>Our Products!</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          {groupedCards.map((group) => (
            <ul className="cards__items">
              {group.map((card) => (
                <CardItem
                  src={card.src}
                  text={card.text}
                  label={card.label}
                  path={card.path}
                />
              ))}
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Cards;
