import React from "react";
import "../../App.css";
import Cards from "../Cards";
import Agro from "..//Agro";
import Footer from "../Footer";

export default function Products() {
  return (
    <>
      <div className="products">
        <h1 className="">PRODUCTS</h1>
        <h3>
          Enjoy our energetically fueling Products that are innovated to address
          your problems and meet your needs.
        </h3>
      </div>
      <Cards />
      <Agro />
      <Footer />
    </>
  );
}
