import React from "react";

const FeedsBlockItem = (props) => {
  return (
    <div className={props.newFeed}>
      <div className="content">
        <h2>{props.title}</h2>

        {props.text.map((paragraph) => (
          <p>{paragraph}</p>
        ))}
      </div>
      <div className="image-block">
        <img src={props.img1} alt="img" />
        <img src={props.img2} alt="img" />
      </div>
    </div>
  );
};

export default FeedsBlockItem;
