import React from "react";
import "../../App.css";
import Cards from "../Cards";
import HeroSection from "../HeroSection";
import Footer from "../Footer";
import Services from "../Services";
import FeedsBlock from "../FeedsBlock";
import AgProducts from "../Agro";

function Home({ footerContactRef }) {
  return (
    <>
      <HeroSection />
      <Cards /> <AgProducts />
      <FeedsBlock />
      <Services />
      <Footer footerContactRef={footerContactRef} />
    </>
  );
}

export default Home;
