import React, { useEffect } from "react";
import Navbar from "./components/NewNavbar";
import "./App.css";
import Home from "./components/pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Services from "./components/pages/Services";
import Products from "./components/pages/Products";
import SignUp from "./components/pages/SignUp";
import { useRef } from "react";

function App() {
  useEffect(() => {
    document.title = "tugza.com";
  }, []);
  const footerContactRef = useRef(null);
  const scrollToFooter = (e) => {
    e.preventDefault();
    if (footerContactRef.current) {
      footerContactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Router>
      <Navbar scrollToFooter={scrollToFooter} />
      <Routes>
        <Route
          path="/"
          exact
          element={<Home footerContactRef={footerContactRef} />}
        />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/sign-up" element={<SignUp />} />
      </Routes>
    </Router>
  );
}

export default App;
