import React, { useState, useEffect } from "react";
import "../App.css";
import "./HeroSection.css";

function HeroSection() {
  const images = [
    "/images/v11.jpg",
    "/images/v3.jpg",

    // "/images/v12.jpg",
    // "/images/ss4.jpg",
    // "/images/s04.jpg",
    // "/images/ss2.jpg",
  ];
  const [bgImageUrl, setBgImageUrl] = useState(images[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextImageUrl =
        images[(images.indexOf(bgImageUrl) + 1) % images.length];
      setBgImageUrl(nextImageUrl);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [bgImageUrl, images]);

  const heroContainerStyle = {
    background: `url("${bgImageUrl}") center center/cover no-repeat`,
  };

  return (
    <div className="hero-container" style={heroContainerStyle}>
      <h1>INNOVATION AWAITS</h1>
      <h2>Revolutionize the edge of Digital and problem free world</h2>

      <p>
        We Serve you faster at remarkable quality with our problem solving and
        specialized capabilities.
      </p>
    </div>
  );
}

export default HeroSection;
