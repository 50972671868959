import React, { useState } from "react";
import "./NewNavbar.css";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaShoppingCart,
  FaCogs,
  FaEnvelope,
  FaTimes,
  FaBars,
} from "react-icons/fa";

export default function NewNavbar({ scrollToFooter }) {
  const [clicked, setClicked] = useState(true);

  const handleClick = () => {
    setClicked(!clicked);
  };
  const cName = "icons";

  const navbar = [
    { title: "Home", url: "/", icon: FaHome, className: "nav-links" },
    {
      title: "Services",
      url: "/services",
      icon: FaCogs,
      className: "nav-links",
    },
    {
      title: "Products",
      url: "/products",
      icon: FaShoppingCart,
      className: "nav-links",
    },
    { title: "Contact", url: "/", icon: FaEnvelope, className: "nav-links" },
  ];

  function handleLinkClick() {
    setClicked(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  function redirectHome() {
    window.location.href = "/";
  }

  return (
    <div className="navbar">
      <div className="nbar-logo" onClick={redirectHome}>
        <img src="/images/icons/logo.jpg" alt="logo" />{" "}
        <h2 className="navbar-logo">TUGZA INNOVATIONS </h2>
      </div>
      <div className="icon" onClick={handleClick}>
        {clicked ? (
          <FaBars className="barIcons" />
        ) : (
          <FaTimes className="barIcons" />
        )}
      </div>
      <ul className={clicked ? "nav-menu" : "nav-menu active"}>
        {navbar.map((item) => (
          <li className={item.className}>
            <Link
              to={item.url}
              style={{ textDecoration: "none", color: "white" }}
              onClick={handleLinkClick}
            >
              {<item.icon className={cName} />}
              {item.title === "Contact" ? (
                <span onClick={scrollToFooter}>{item.title}</span>
              ) : (
                item.title
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
