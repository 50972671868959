import React from "react";
import { Link } from "react-router-dom";
import {
  FaYoutube,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import "./Footer.css";

function Footer({ footerContactRef }) {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          We specialize in innovation with cutting-edge Software Development,
          and tycoon Websites, Apps, Systems, and APIs.
        </p>
        <div className="custom-horizontal-line"></div>
      </section>

      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>
            <Link to="/">How it works</Link>
            <Link to="/">Testimonials</Link>
            <Link to="/">Careers</Link>
            <Link to="/">Partners</Link>
            <Link to="/">Terms of Service</Link>
          </div>
        </div>

        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <div className="footer__contact" ref={footerContactRef}>
              <span>
                <FaPhone /> <a href="tel: +251937072856">+251937072856</a>{" "}
              </span>
              <span>
                <FaPhone /> <a href="tel: +251704939313">+251704939313</a>
              </span>

              <span>
                <FaEnvelope />{" "}
                <a href="mailto:tugzaiit@gmail.com">tugzaiit@gmail.com</a>
              </span>
              <span>
                <FaMapMarkerAlt />{" "}
                <a href="">Office: Global bldg 2nd floor, mekelle </a>
              </span>
              <span>
                Workshop: mesfin cluster around medebr/sos Addis Abeba, Safari
                around skybar
              </span>
            </div>
          </div>
        </div>
      </div>

      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              TUGZA
              {/* <FaBars /> */}
            </Link>
          </div>
          <small className="website-rights">© ® TUGZA-IInTech 2023</small>
          <div className="social-icons">
            <SocialIconLink
              className="facebook"
              url="https://www.facebook.com/"
              label="Facebook"
              icon={<FaFacebook />}
            />
            <SocialIconLink
              className="instagram"
              url="/"
              label="Instagram"
              icon={<FaInstagram />}
            />
            <SocialIconLink
              className="youtube"
              url="https://www.youtube.com"
              label="Youtube"
              icon={<FaYoutube />}
            />
            <SocialIconLink
              className="twitter"
              url="/"
              label="Twitter"
              icon={<FaTwitter />}
            />
            <SocialIconLink
              className="linkedin"
              url="/"
              label="LinkedIn"
              icon={<FaLinkedin />}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

function SocialIconLink({ className, url, label, icon }) {
  return (
    <Link className={`social-icon-link ${className}`} to={url} target="_blank">
      {icon}
    </Link>
  );
}

export default Footer;
