import React from "react";
import "./Agro.css";

function AgProducts() {
  const ag_images = [
    "/images/products/p7.jpg",
    "/images/products/p10.jpg",
    "/images/products/p9.jpg",
  ];

  return (
    <div className="ag-products">
      <div className="ag-content">
        <h2>Irrigation technologies </h2>
        <h4>For ploughing, row planting, harvesting and collecting</h4>
      </div>
      <div className="ag-images">
        {ag_images.map((img) => (
          <img src={img} alt="img" />
        ))}
      </div>
    </div>
  );
}

export default AgProducts;
